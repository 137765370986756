import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Link from '../../utils/link';

import {
  NavLabel,
  NavLink,
  NavNoLink,
  NoChildrenLabel,
  NoChildrenViewAll,
  SecondaryItem,
  SecondaryList,
  SecondaryTrigger
} from './styles';

import { getNavigationProps, getNavigation } from '../../utils/navigation';
import { logAmplitude } from '../../utils/amplitude';

const NavigationNoChildren = (props) => {
  const nav2 = getNavigation(props.navigation, 2);
  const [parent] = useState(props.parent);

  const trackNavClick = (item, isParent = false) => {
    const navigationTerm = !isParent
      ? `${props.navigation['1']?.find((x) => x.id === item.parent_id)?.name.toLowerCase()}.${item.name.toLowerCase()}`
      : item.name.toLowerCase();
    logAmplitude('Navigated Site', {
      navigationTerm,
      path: item.navigation_url,
      navigationType: props.isMobile ? 'mobile burger menu' : 'desktop top nav'
    });
  };

  return (
    nav2 && parent
      ? (
        <SecondaryList role="menu" noChildren>
          <NoChildrenLabel noChildrenLabel>
            <Link
              {...getNavigationProps({ item: parent })}
              passHref
            >
              <NavLink onClick={() => trackNavClick(parent, true)}>
                {parent.name}
              </NavLink>
            </Link>
          </NoChildrenLabel>

          <SecondaryItem role="menuitem" viewAll>
            <Link
              {...getNavigationProps({ item: parent, viewAll: true })}
              passHref
            >
              <NavLink>View All</NavLink>
            </Link>
          </SecondaryItem>

          {
              nav2.filter((x) => x.parent_id === parent.id).map((item) => (
                item.has_children
                  ? (
                    <SecondaryItem role="menuitem" key={item.id}>
                      <SecondaryTrigger
                        onChange={(e) => props.handleChange(e, item)}
                        type="checkbox"
                        id={item.id}
                        name="secondary"
                      />
                      <NavLabel htmlFor={item.id}>{item.name}</NavLabel>

                      {
                        item.header_link
                          ? (
                            <Link
                              {...getNavigationProps({ item })}
                              passHref
                            >
                              <NavLink onClick={() => trackNavClick(item)}>
                                {item.name}
                              </NavLink>
                            </Link>
                          ) : (
                            <NavNoLink onClick={() => trackNavClick(item)}>
                              {item.name}
                            </NavNoLink>
                          )
                      }

                      { props.renderTertiary(item) }
                    </SecondaryItem>
                  )
                  : (
                    <SecondaryItem role="menuitem" key={item.id}>
                      <Link
                        {...getNavigationProps({ item })}
                        passHref
                      >
                        <NavLink onClick={() => trackNavClick(item)}>
                          {item.name}
                        </NavLink>
                      </Link>
                    </SecondaryItem>
                  )
              ))
          }

          <NoChildrenViewAll role="menuitem">
            <Link
              {...getNavigationProps({ item: parent, viewAll: true })}
              passHref
            >
              <NavLink onClick={() => trackNavClick(parent, true)}>
                View All
              </NavLink>
            </Link>
          </NoChildrenViewAll>
        </SecondaryList>
      ) : null
  );
};

NavigationNoChildren.propTypes = {
  handleChange: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  parent: PropTypes.object.isRequired,
  renderTertiary: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default memo(NavigationNoChildren);
